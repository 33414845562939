<template>
  <div class="home overflow-hidden">
    <Loading v-if="isUniversalLoading" />
    <div class="d-flex align-items-center shadow-lg px-1 header">
      <img class="rounded-circle user-avatar" :src="require(`@/assets/images/alex/${alexImage}`)" alt="" srcset="" />
      <div class="row">
        <div class="font-weight-bold text-wrap col-12">{{ alexName }}</div>
        <div class="d-flex align-items-center small font-weight-light col-12">
          <div class="rounded online-indicatior bg-success"></div>
          Always Active
        </div>
      </div>
    </div>
    <div class="row overflow-auto message-section" ref="scrollableDiv">
      <div class="col-12 p-0 mb-1">
        <div v-for="item in this.conversationData" :key="item.title">
          <BotMessage v-if="!item.user" :data="item" :callback="submitUserResponse" :showImage="showImage(item)" />
          <UserMessage
            v-if="item.user"
            :data="item.selectedItems.length != 0 ? item.selectedItems.map((e) => e.title).join(' | ') : item.label"
          />
        </div>
        <LoadingResponse v-if="isResonseLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import { alvoxOnboardingConfig, alvoxOnboardingConfigNewUser } from "./config";
import OrgConfig from "@/assets/data/organizationConfig";
import UserMessage from "@/components/messages/UserMessage.vue";
import BotMessage from "@/components/messages/BotMessage.vue";
import LoadingResponse from "@/components/messages/LoadingResponse.vue";

export default {
  data() {
    return {
      alexName: "",
      answers: [],
      configData: {},
      isResonseLoading: false,
      isUniversalLoading: false,
    };
  },
  components: {
    Loading,
    UserMessage,
    BotMessage,
    LoadingResponse,
  },
  methods: {
    pushConversationData(data) {
      this.$store.commit("quiz/PUSH_CONVERSATION_DATA", data);
    },
    showImage(item) {
      if (item.buttons || item.choices || (!item.buttons && !item.choices && !item.action_id)) {
        return true;
      }
      return false;
    },
    checkNextMessage() {
      var lastMessage = this.conversationData[this.conversationData.length - 1];
      if (lastMessage.action_id) {
        this.pushConversationData(this.configData[lastMessage.action_id]);
      }
    },
    startConversation() {
      this.pushConversationData(this.configData.init);
      this.checkNextMessage();
      this.$nextTick(() => {
        this.scrollToElement();
      });
    },
    submitUserResponse(data) {
      this.isResonseLoading = true;
      this.pushConversationData({ ...data, user: true });
      this.$nextTick(() => {
        this.scrollToElement();
      });
      setTimeout(() => {
        this.pushConversationData(this.configData[data.action_id]);
        this.checkNextMessage();
        data.selectedItems.length != 0
          ? this.answers.push({
              field: { id: data.id, ref: data.ref, type: data.type },
              choices: { labels: data.selectedItems.map((e) => e.title) },
            })
          : this.answers.push({
              field: { id: data.id, ref: data.ref, type: data.type },
              choice: { label: data.label },
            });
        this.$nextTick(() => {
          this.scrollToElement();
        });
        this.isResonseLoading = false;
      }, 2000);
    },
    setOrgInfo(orgSlug) {
      var orgDetails = OrgConfig[orgSlug];
      if (!orgDetails) orgDetails = OrgConfig.default;
      this.alexName = orgDetails.alexName;
      this.$store.commit("user/SET_ORGANIZATION_ALEX_IMAGE_PATH", orgDetails.alexImagePath);
      let domStyle = document.documentElement.style;
      domStyle.setProperty("--org-color", orgDetails.orgColor);
      domStyle.setProperty("--button-color", orgDetails.buttons);
      domStyle.setProperty("--background", orgDetails.background);
      domStyle.setProperty("--text-primary", orgDetails.textPrimary);
      domStyle.setProperty("--text-secondary", orgDetails.textSecondary);
      domStyle.setProperty("--user-reply-color", orgDetails.userReplyColor);
      domStyle.setProperty("--bot-reply-color", orgDetails.botReplyColor);
    },
    scrollToElement() {
      const scrollableDiv = this.$refs.scrollableDiv;
      if (scrollableDiv) {
        const scrollHeight = scrollableDiv.scrollHeight;
        const clientHeight = scrollableDiv.clientHeight;
        const maxScrollTop = scrollHeight - clientHeight;

        const scrollStep = 10; // step size as needed
        const scrollInterval = 10; // interval for smoother animation

        let currentScrollTop = scrollableDiv.scrollTop;
        const scrollDown = () => {
          if (currentScrollTop < maxScrollTop) {
            currentScrollTop = Math.min(currentScrollTop + scrollStep, maxScrollTop);
            scrollableDiv.scrollTop = currentScrollTop;
            setTimeout(scrollDown, scrollInterval);
          }
        };
        scrollDown();
      }
    },
  },
  watch: {
    conversationData() {
      var lastMessage = this.conversationData[this.conversationData.length - 1];
      if (!lastMessage.buttons && !lastMessage.action_id) {
        var definition = [];
        for (let key in this.configData) {
          definition.push(this.configData[key]);
        }
        var payload = {
          form_response: {
            hidden: {
              org: this.orgSlug,
              email: this.email,
              gbuid: this.userId,
              first_name: this.userName,
              gborgid: this.organizationId,
              tzn: this.timezone,
            },
            answers: this.answers.slice(1),
          },
        };
        this.$store.dispatch("bot/updateAlexpreferences", payload).catch((err) => console.log(err));
        return;
      }
    },
  },
  computed: {
    alexImage() {
      return this.$store.state.user.orgAlexImage;
    },
    conversationData() {
      return this.$store.state.quiz.conversationData;
    },
    userId() {
      return this.$store.state.user.userId;
    },
    email() {
      return this.$store.state.user.email;
    },
    userName() {
      return this.$store.state.user.name;
    },
    timezone() {
      return this.$store.state.user.timezone;
    },
    orgSlug() {
      return this.$store.state.user.orgSlug;
    },
    organizationId() {
      return this.$store.state.user.organizationId;
    },
  },
  created() {
    this.isUniversalLoading = true;
    this.setOrgInfo(this.$route.query.orgSlug);
    this.$store.commit("user/SET_NAME", this.$route.query.name);
    this.$store.commit("user/SET_USER", this.$route.query.userId);
    this.$store.commit("user/SET_EMAIL", this.$route.query.email);
    this.$store.commit("user/SET_TIMEZONE", this.$route.query.tzn);
    this.$store.commit("user/SET_ORGANIZATION_SLUG", this.$route.query.orgSlug);
    this.$store.commit("user/SET_ORGANIZATION", this.$route.query.organizationId);
    this.$store
      .dispatch("bot/getALexDigest", `Bearer ${this.$route.query.token}`)
      .then((res) => {
        if (res) {
          this.configData = alvoxOnboardingConfig(this.$route.query.name, this.alexName);
        } else {
          this.configData = alvoxOnboardingConfigNewUser(this.$route.query.name, this.alexName);
        }
      })
      .catch(() => (this.configData = alvoxOnboardingConfigNewUser(this.$route.query.name)))
      .finally(() => {
        this.startConversation();
        this.isUniversalLoading = false;
      });
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

.post-button > img {
  height: 100%;
  width: 100%;
  border-radius: 50px;
  background-color: transparent;
}

.post-button {
  height: 60%;
  padding-left: 10px;
}

.online-indicatior {
  height: 10px;
  width: 10px;
  margin-right: 4px;
}

.header {
  height: 10vh;
  padding: 5px;
  min-height: 50px;
  color: var(--text-secondary);
  background-color: var(--background) !important;
}

.message-section {
  height: 90vh;
}

.input-section {
  height: 7%;
  min-height: 60px;
}

.user-placeholder {
  width: 2.6rem;
  height: 2.6rem;
}

.user-avatar {
  width: 2.2rem;
  height: 2.2rem;
  object-fit: contain;
  border-radius: 15px;
  border: 2px solid var(--org-color);
  background-color: var(--background);
}

.input-field {
  width: 100%;
  height: 60%;
  border-radius: 15px;
  border: 2px solid var(--org-color);
}

.end-div {
  height: 1px;
  width: 1px;
}

.bot-message {
  color: var(--text-primary) !important;
  background-color: var(--org-color) !important;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.user-message {
  color: var(--text-secondary) !important;
  background-color: var(--user-reply-color) !important;
  border-top-left-radius: 2rem;
  border-top-right-radius: 0.3rem;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.home {
  height: 100vh;
  background: var(--background);
}
</style>
