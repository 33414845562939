const alvoxOnboardingConfigNewUser = (name, botName) => {
  return {
    init: {
      id: "gtbd_1",
      is_tree: false,
      action_id: "intro",
      title: `Hi ${name}, let's update your learning preferences.`,
    },
    intro: {
      id: "gtbd_2",
      is_tree: true,
      title: `I am ${botName}, an Adaptive Learning VOX to personalize and create your learning calendar.`,
      buttons: [
        {
          id: "gtbdb_1",
          action_id: "optIn",
          label: "Activate",
        },
      ],
    },
    optIn: {
      id: "gtbd_3",
      is_tree: false,
      action_id: "activate",
      title: `Opt in to ALVOX...`,
    },
    activate: {
      id: "gtbd_4",
      is_tree: true,
      title: `Choose a quick activation or set your preferences step by step. You can pause anytime to temporarily stop notifications until you're ready to receive them again.`,
      buttons: [
        {
          id: "gtbdb_2",
          ref: "field_optin",
          type: "multiple_choice",
          action_id: "questComplete",
          label: "Set default preferences for Mondays",
        },
        {
          id: "gtbdb_3",
          ref: "field_optin",
          type: "multiple_choice",
          action_id: "custompreferences",
          label: "Let me configure my weekly preferences",
        },
        {
          id: "gtbdb_4",
          ref: "field_optin",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "Pause my subscription",
        },
      ],
    },
    custompreferences: {
      id: "gtbd_5",
      is_tree: false,
      action_id: "learningPref",
      title: "Great! Let's find out your preferred learning formats.",
    },
    learningPref: {
      id: "gtbd_6",
      is_tree: false,
      isRequired: true,
      title: "How do you like to learn?",
      choices: [
        {
          id: "gtbdc_1",
          image: "watching.png",
          title: "I enjoy learning by Watching. i.e. Videos, Vlogs, Online Training",
        },
        {
          id: "gtbdc_2",
          image: "reading.png",
          title: "I prefer learning through Reading. i.e. Books, Blogs, Articles, Forums.",
        },
        {
          id: "gtbdc_3",
          image: "listening.png",
          title: "I would like to Listen to audio. i.e. Audiobooks, Seminars, Podcasts.",
        },
        {
          id: "gtbdc_4",
          image: "interacting.png",
          title: "I like to learn by Interaction. i.e Certifications, Virtual Sessions, Games.",
        },
        {
          id: "gtbdc_5",
          image: "participation.png",
          title: "Learning by Participation. i.e. Events, Hackathons, Webinars.",
        },
        {
          id: "gtbdc_6",
          image: "challenge.svg",
          title: "Learning by Coaching. i.e. Mentorship, Peer Pairing, Development planning.",
        },
        {
          id: "gtbdc_6",
          image: "coaching.svg",
          title: "Challenge-based learning: i.e. Competitive, Bounties, Peer 2 Peer Challenges.",
        },
      ],
      buttons: [
        {
          id: "gtbdb_5",
          type: "picture_choice",
          ref: "field_pref_options",
          action_id: "timeCommitment",
          label: "OK",
        },
      ],
    },
    timeCommitment: {
      id: "gtbd_7",
      is_tree: false,
      action_id: "timeAvailability",
      title: `Time commitment:`,
    },
    timeAvailability: {
      id: "gtbd_8",
      is_tree: false,
      title: `How much time per week do you have available to learn?`,
      buttons: [
        {
          id: "gtbdb_6",
          ref: "field_pref_time",
          type: "multiple_choice",
          action_id: "languagepreferences",
          label: "Less than 15 minutes",
        },
        {
          id: "gtbdb_7",
          ref: "field_pref_time",
          type: "multiple_choice",
          action_id: "languagepreferences",
          label: "Less than an hour",
        },
        {
          id: "gtbdb_8",
          ref: "field_pref_time",
          type: "multiple_choice",
          action_id: "languagepreferences",
          label: "Up to 2 hours",
        },
        {
          id: "gtbdb_9",
          ref: "field_pref_time",
          type: "multiple_choice",
          action_id: "languagepreferences",
          label: "I have no time constraint",
        },
      ],
    },
    languagepreferences: {
      id: "gtbd_9",
      is_tree: false,
      action_id: "languageSelection",
      title: `Would you like to receive your learning material in a language other than English?`,
    },
    languageSelection: {
      id: "gtbd_10",
      is_tree: false,
      isRequired: false,
      title: `Skip this question by clicking skip button below if you prefer to only receive English suggestions.`,
      choices: [
        {
          id: "gtbdc_7",
          title: "Spanish",
        },
        {
          id: "gtbdc_8",
          title: "French",
        },
        {
          id: "gtbdc_9",
          title: "Portuguese",
        },
        {
          id: "gtbdc_10",
          title: "Dutch",
        },
        {
          id: "gtbdc_11",
          title: "Italian",
        },
      ],
      buttons: [
        {
          id: "gtbdb_10",
          type: "multiple_choice",
          ref: "field_altlanguage",
          action_id: "daypreferences",
          label: "Skip",
        },
        {
          id: "gtbdb_11",
          ref: "field_altlanguage",
          type: "multiple_choice",
          action_id: "daypreferences",
          label: "Ok",
        },
      ],
    },
    daypreferences: {
      id: "gtbd_11",
      is_tree: false,
      action_id: "daySelection",
      title: `When should I send you your curated learning content?`,
    },
    daySelection: {
      id: "gtbd_12",
      is_tree: false,
      isRequired: true,
      title: `What day of the week would you like to receive your suggested learning experience?`,
      choices: [
        {
          id: "gtbdc_12",
          title: "Monday",
        },
        {
          id: "gtbdc_13",
          title: "Tuesday",
        },
        {
          id: "gtbdc_14",
          title: "Wednesday",
        },
        {
          id: "gtbdc_15",
          title: "Thursday",
        },
        {
          id: "gtbdc_16",
          title: "Friday",
        },
        {
          id: "gtbdc_17",
          title: "Saturday",
        },
        {
          id: "gtbdc_18",
          title: "Sunday",
        },
      ],
      buttons: [
        {
          id: "gtbdb_13",
          ref: "field_dow",
          type: "multiple_choice",
          action_id: "timepreferences",
          label: "Ok",
        },
      ],
    },
    timepreferences: {
      id: "gtbd_13",
      is_tree: false,
      action_id: "timeSelection",
      title: `Let's block a focus hour...`,
    },
    timeSelection: {
      id: "gtbd_14",
      is_tree: false,
      title: `What time of day do you prefer to receive your learning recommendation? The time you are selecting will be saved in your local time, based on the country office location in which you are permanently based.`,
      buttons: [
        {
          id: "gtbdb_14",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "questComplete",
          label: "04:00 AM",
        },
        {
          id: "gtbdb_14",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "questComplete",
          label: "05:00 AM",
        },
        {
          id: "gtbdb_15",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "questComplete",
          label: "06:00 AM",
        },
        {
          id: "gtbdb_16",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "questComplete",
          label: "07:00 AM",
        },
        {
          id: "gtbdb_17",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "questComplete",
          label: "08:00 AM",
        },
        {
          id: "gtbdb_18",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "questComplete",
          label: "09:00 AM",
        },
        {
          id: "gtbdb_19",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "questComplete",
          label: "10:00 AM",
        },
        {
          id: "gtbdb_20",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "questComplete",
          label: "11:00 AM",
        },
        {
          id: "gtbdb_21",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "questComplete",
          label: "12:00 PM",
        },
        {
          id: "gtbdb_22",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "questComplete",
          label: "01:00 PM",
        },
        {
          id: "gtbdb_23",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "questComplete",
          label: "02:00 PM",
        },
        {
          id: "gtbdb_24",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "questComplete",
          label: "03:00 PM",
        },
        {
          id: "gtbdb_25",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "questComplete",
          label: "04:00 PM",
        },
        {
          id: "gtbdb_26",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "questComplete",
          label: "05:00 PM",
        },
        {
          id: "gtbdb_27",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "questComplete",
          label: "06:00 PM",
        },
        {
          id: "gtbdb_28",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "questComplete",
          label: "07:00 PM",
        },
        {
          id: "gtbdb_29",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "questComplete",
          label: "08:00 PM",
        },
        {
          id: "gtbdb_30",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "questComplete",
          label: "09:00 PM",
        },
        {
          id: "gtbdb_31",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "questComplete",
          label: "10:00 PM",
        },
        {
          id: "gtbdb_32",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "questComplete",
          label: "11:00 PM",
        },
        {
          id: "gtbdb_33",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "questComplete",
          label: "00:00 AM",
        },
      ],
    },
    questComplete: {
      id: "gtbd_15",
      is_tree: false,
      action_id: "updatedpreferences",
      title: `Your first QUEST is complete!`,
    },
    updatedpreferences: {
      id: "gtbd_16",
      is_tree: false,
      title: `Thanks! I've updated your learning preferences. I will be here shall you need to update the schedule again. You may close the chat.`,
    },
  };
};

const alvoxOnboardingConfig = (name, botName) => {
  return {
    init: {
      id: "gtbd_1",
      is_tree: false,
      action_id: "intro",
      title: `Hi ${name}, let's update your learning preferences.`,
    },
    intro: {
      id: "gtbd_2",
      is_tree: true,
      title: `I am ${botName}, an Adaptive Learning VOX to personalize and create your learning calendar.`,
      buttons: [
        {
          id: "gtbdb_1",
          action_id: "activate",
          label: "Update preferences",
        },
      ],
    },
    activate: {
      id: "gtbd_4",
      is_tree: true,
      title: `Choose a quick activation or set your preferences step by step. You can pause anytime to temporarily stop notifications until you're ready to receive them again.`,
      buttons: [
        {
          id: "gtbdb_2",
          ref: "field_optin",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "Set default preferences for Mondays",
        },
        {
          id: "gtbdb_3",
          ref: "field_optin",
          type: "multiple_choice",
          action_id: "custompreferences",
          label: "Let me configure my weekly preferences",
        },
        {
          id: "gtbdb_4",
          ref: "field_optin",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "Pause my subscription",
        },
      ],
    },
    custompreferences: {
      id: "gtbd_5",
      is_tree: false,
      action_id: "learningPref",
      title: "Great! Let's find out your preferred learning formats.",
    },
    learningPref: {
      id: "gtbd_6",
      is_tree: false,
      isRequired: true,
      title: "How do you like to learn?",
      choices: [
        {
          id: "gtbdc_1",
          image: "watching.svg",
          title: "I enjoy learning by Watching. i.e. Videos, Vlogs, Online Training",
        },
        {
          id: "gtbdc_2",
          image: "reading.svg",
          title: "I prefer learning through Reading. i.e. Books, Blogs, Articles, Forums.",
        },
        {
          id: "gtbdc_3",
          image: "audio.svg",
          title: "I would like to Listen to audio. i.e. Audiobooks, Seminars, Podcasts.",
        },
        {
          id: "gtbdc_4",
          image: "interaction.svg",
          title: "I like to learn by Interaction. i.e Certifications, Virtual Sessions, Games.",
        },
        {
          id: "gtbdc_5",
          image: "participation.svg",
          title: "Learning by Participation. i.e. Events, Hackathons, Webinars.",
        },
        {
          id: "gtbdc_6",
          image: "challenge.svg",
          title: "Learning by Coaching. i.e. Mentorship, Peer Pairing, Development planning.",
        },
        {
          id: "gtbdc_6",
          image: "coaching.svg",
          title: "Challenge-based learning: i.e. Competitive, Bounties, Peer 2 Peer Challenges, On-the-job.",
        },
      ],
      buttons: [
        {
          id: "gtbdb_5",
          type: "picture_choice",
          ref: "field_pref_options",
          action_id: "timeCommitment",
          label: "OK",
        },
      ],
    },
    timeCommitment: {
      id: "gtbd_7",
      is_tree: false,
      action_id: "timeAvailability",
      title: `Time commitment:`,
    },
    timeAvailability: {
      id: "gtbd_8",
      is_tree: false,
      title: `How much time per week do you have available to learn?`,
      buttons: [
        {
          id: "gtbdb_6",
          ref: "field_pref_time",
          type: "multiple_choice",
          action_id: "languagepreferences",
          label: "Less than 15 minutes",
        },
        {
          id: "gtbdb_7",
          ref: "field_pref_time",
          type: "multiple_choice",
          action_id: "languagepreferences",
          label: "Less than an hour",
        },
        {
          id: "gtbdb_8",
          ref: "field_pref_time",
          type: "multiple_choice",
          action_id: "languagepreferences",
          label: "Up to 2 hours",
        },
        {
          id: "gtbdb_9",
          ref: "field_pref_time",
          type: "multiple_choice",
          action_id: "languagepreferences",
          label: "I have no time constraint",
        },
      ],
    },
    languagepreferences: {
      id: "gtbd_9",
      is_tree: false,
      action_id: "languageSelection",
      title: `Would you like to receive your learning material in a language other than English?`,
    },
    languageSelection: {
      id: "gtbd_10",
      is_tree: false,
      isRequired: false,
      title: `Skip this question by clicking skip button below if you prefer to only receive English suggestions.`,
      choices: [
        {
          id: "gtbdc_7",
          title: "Spanish",
        },
        {
          id: "gtbdc_8",
          title: "French",
        },
        {
          id: "gtbdc_9",
          title: "Portuguese",
        },
        {
          id: "gtbdc_10",
          title: "Dutch",
        },
        {
          id: "gtbdc_11",
          title: "Italian",
        },
      ],
      buttons: [
        {
          id: "gtbdb_10",
          type: "multiple_choice",
          ref: "field_altlanguage",
          action_id: "daypreferences",
          label: "Skip",
        },
        {
          id: "gtbdb_11",
          ref: "field_altlanguage",
          type: "multiple_choice",
          action_id: "daypreferences",
          label: "Ok",
        },
      ],
    },
    daypreferences: {
      id: "gtbd_11",
      is_tree: false,
      action_id: "daySelection",
      title: `When should I send you your curated learning content?`,
    },
    daySelection: {
      id: "gtbd_12",
      is_tree: false,
      isRequired: true,
      title: `What day of the week would you like to receive your suggested learning experience?`,
      choices: [
        {
          id: "gtbdc_12",
          title: "Monday",
        },
        {
          id: "gtbdc_13",
          title: "Tuesday",
        },
        {
          id: "gtbdc_14",
          title: "Wednesday",
        },
        {
          id: "gtbdc_15",
          title: "Thursday",
        },
        {
          id: "gtbdc_16",
          title: "Friday",
        },
        {
          id: "gtbdc_17",
          title: "Saturday",
        },
        {
          id: "gtbdc_18",
          title: "Sunday",
        },
      ],
      buttons: [
        {
          id: "gtbdb_13",
          ref: "field_dow",
          type: "multiple_choice",
          action_id: "timepreferences",
          label: "Ok",
        },
      ],
    },
    timepreferences: {
      id: "gtbd_13",
      is_tree: false,
      action_id: "timeSelection",
      title: `Let's block a focus hour...`,
    },
    timeSelection: {
      id: "gtbd_14",
      is_tree: false,
      title: `What time of day do you prefer to receive your learning recommendation? The time you are selecting will be saved in your local time, based on the country office location in which you are permanently based.`,
      buttons: [
        {
          id: "gtbdb_14",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "04:00 AM",
        },
        {
          id: "gtbdb_14",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "05:00 AM",
        },
        {
          id: "gtbdb_15",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "06:00 AM",
        },
        {
          id: "gtbdb_16",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "07:00 AM",
        },
        {
          id: "gtbdb_17",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "08:00 AM",
        },
        {
          id: "gtbdb_18",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "09:00 AM",
        },
        {
          id: "gtbdb_19",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "10:00 AM",
        },
        {
          id: "gtbdb_20",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "11:00 AM",
        },
        {
          id: "gtbdb_21",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "12:00 PM",
        },
        {
          id: "gtbdb_22",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "01:00 PM",
        },
        {
          id: "gtbdb_23",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "02:00 PM",
        },
        {
          id: "gtbdb_24",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "03:00 PM",
        },
        {
          id: "gtbdb_25",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "04:00 PM",
        },
        {
          id: "gtbdb_26",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "05:00 PM",
        },
        {
          id: "gtbdb_27",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "06:00 PM",
        },
        {
          id: "gtbdb_28",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "07:00 PM",
        },
        {
          id: "gtbdb_29",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "08:00 PM",
        },
        {
          id: "gtbdb_30",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "09:00 PM",
        },
        {
          id: "gtbdb_31",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "10:00 PM",
        },
        {
          id: "gtbdb_32",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "11:00 PM",
        },
        {
          id: "gtbdb_33",
          ref: "field_tod",
          type: "multiple_choice",
          action_id: "updatedpreferences",
          label: "00:00 AM",
        },
      ],
    },
    updatedpreferences: {
      id: "gtbd_16",
      is_tree: false,
      title: `Thanks! I've updated your learning preferences. I will be here shall you need to update the schedule again. You may close the chat.`,
    },
  };
};

export {alvoxOnboardingConfig, alvoxOnboardingConfigNewUser};
